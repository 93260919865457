<template>
  <div>
    <div class="top">
      <div class="logo">
        <div class="logo-left">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="logo-right">
          <p style="font-size: 30px; font-weight: bold">
            {{ title }}
          </p>
          <p style="font-size: 20px">www.zhzqw.cn</p>
        </div>
      </div>
      <div class="tab">
        <div class="box active">首页</div>
        <div class="box" @click="noticeClick">通知</div>
        <!-- <div class="box">组织</div> -->
        <div class="box" @click="enterpriseClick">企业</div>
        <div class="box" @click="genealogyClick">族谱</div>
        <div class="box" @click="ancestryClick">家谱树</div>
        <!-- <div class="box">宗亲分布</div> -->
        <!-- <div class="box" @click="$router.push('./celebrity')">名人</div>
        <div class="box" @click="$router.push('./culture')">文化典故</div>
        <div class="box" @click="$router.push('./merit')">功德</div> -->
      </div>
    </div>
    <div class="content">
      <div class="con">
        <div class="bigbox">
          <div class="banner">
            <!-- <img src="@/assets/png/bwh/download.png.png" alt="" /> -->

            <el-carousel
              :autoplay="true"
              indicator-position="none"
              arrow="hover"
              style="width: 100%; height: 350px"
            >
              <el-carousel-item
                v-for="(item, index) in slicedArrayList"
                :key="index"
                style="width: 100%; height: 350px"
              >
                <div
                  class="picture"
                  :style="{
                    'background-image': `url(${item.url})`,
                  }"
                >
                  <div
                    style="
                      position: absolute;
                      bottom: 0;
                      width: 100%;
                      padding: 10px;
                    "
                  >
                    {{ item.title }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="new">
            <div style="position: relative; display: flex">
              <img src="@/assets/png/bwh/windows.png" alt="" />
              <p class="a">通知</p>
              <p
                style="
                  text-align: center;
                  position: absolute;
                  top: 66px;
                  left: 80px;
                  font-size: 20px;
                  font-weight: bold;
                  cursor: pointer;
                  z-index: 99999;
                "
                @click="familyDetails(noticeListsFirst)"
              >
                <!-- 濮阳县召开2019中华张姓拜祖大典本地张 -->
                {{ noticeListsFirst.title }}
              </p>
            </div>
            <div class="new-con">
              <div style="display: flex">
                <div
                  class="new-con-text1"
                  style="/* width: 120px; */"
                  v-html="noticeListsFirst.articleText"
                >
                  <!-- 3月27日下午4时，濮阳县召开了2019中国濮阳中华张姓拜祖大典本地张氏宗亲参拜
                  誓师大会。濮阳县21个乡镇的党委副书记、组织委员参加了会议。 -->
                </div>
                <div>...</div>
              </div>
              <div style="width: 100%; margin-top: 70px">
                <div
                  class="new-con-text2"
                  v-for="(item, index) in noticeLists"
                  :key="index"
                >
                  <p
                    style="cursor: pointer; z-index: 99999"
                    @click="familyDetails(item)"
                  >
                    {{ item.title.slice(0, 20) }}
                  </p>
                  <p>{{ item.createTime }}</p>
                </div>
                <!-- <div class="new-con-text2">
                  <p>世界“钨钢大王”廖万隆莅濮寻根谒祖</p>
                  <p>2019/08/26</p>
                </div>
                <div class="new-con-text2">
                  <p>2018中华张姓拜祖大典期间“挥公精神论坛”</p>
                  <p>2019/08/26</p>
                </div>
                <div class="new-con-text2">
                  <p>2018中华张姓拜祖大典欢迎宴会《根系龙乡》</p>
                  <p>2019/08/26</p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="big-box">
          <div class="nav">
            <div class="nav-top">
              <p>组织</p>
              <!-- <p>+更多</p> -->
            </div>
            <div class="nav-con con1" style="padding-top: 42px">
              <!-- <p v-for="(item,index) in organizationList" :key="index">{{ item }}</p> -->

              <div
                style="display: flex; margin: 40px 0"
                v-for="(item, index) in organizationList"
                :key="index"
              >
                <div v-if="item.userList.length > 0">{{ item.name }}：</div>
                <div v-for="(it, idx) in item.userList.slice(0, 4)" :key="idx">
                  {{ it.name }}&nbsp;&nbsp;
                </div>
              </div>
              <!-- <div style="display: flex; margin: 40px 0">
                <div>秘书长：</div>
                <div>张某某、张某某、张某某、张某某</div>
              </div>
              <div style="display: flex; margin: 40px 0">
                <div>副秘书长：</div>
                <div>张某某、张某某、张某某、张某某</div>
              </div> -->

              <!-- <p>会长：张某某</p>
              <p>秘书长：张某某、张某某、张某某、张某某</p>
              <p>副秘书长：张某某、张某某、张某某、张某某</p> -->
            </div>
          </div>
          <div class="nav">
            <div class="nav-top">
              <p>企业</p>
              <p style="cursor: pointer" @click="moreClick">+更多</p>
            </div>
            <div class="nav-con con2">
              <div
                class="content2"
                v-for="item in familyFirmList"
                :key="item.id"
                style="cursor: pointer"
                @click="enterpriseDetails(item)"
              >
                <img :src="item.coverImageUrl" alt="" />
                <div style="height: 44px">
                  {{ item.title }}
                </div>
              </div>
              <!-- <div class="content2">
                <img src="@/assets/png/bwh/图层 38.png" alt="" />
                <p>李氏集团工厂厂房</p>
              </div>
              <div class="content2">
                <img src="@/assets/png/bwh/图层 38.png" alt="" />
                <p>李氏集团工厂厂房</p>
              </div> -->
            </div>
          </div>
          <div class="nav">
            <div class="nav-top">
              <p>宗亲分布</p>
              <!-- <p>+更多</p> -->
            </div>
            <div class="nav-con con3">
              <div class="content3">
                <p>家族总人数:</p>
                <p style="color: #bf1c28">{{ patrilinealList.memberSum }}</p>
              </div>
              <div class="content3">
                <p>已过世人数:</p>
                <p style="color: #333333">{{ patrilinealList.deceasedSum }}</p>
              </div>
              <div class="content3">
                <p>在世人数:</p>
                <p style="color: #bf1c28">{{ patrilinealList.livingSum }}</p>
              </div>
              <div class="content3">
                <p>男性:</p>
                <p style="color: #bf1c28">
                  {{ patrilinealList.maleMemberSum }}
                </p>
              </div>
              <div class="content3">
                <p>女性:</p>
                <p style="color: #bf1c28">
                  {{ patrilinealList.femaleMemberSum }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 寻亲问祖
      menusList: [],
      title: "",
      patrilinealList: {},
      familyTreeId: "",

      name: "",
      // 轮播图
      slicedArrayList: [],

      familyFirmList: [],

      celebrityList: [],

      culturalList: [],

      meritList: [],

      organizationList: [],
      // 通知
      noticeLists: [],
      // 通知
      noticeListsFirst: [],
    };
  },

  // computed: {
  //   noticeListsFirst() {
  //     // 使用正则表达式去除图片标签
  //     return this.noticeListsFirst.articleText.replace(/<img[^>]*>/gi, "");
  //   },
  // },

  watch: {
    // // 监视搜索词变化
    "$route.query": {
      immediate: true,
      // deep: true,
      handler(val, oldval) {
        console.log(val, oldval, "val, oldval");
        if (val.name != undefined) {
          // console.log(val, oldval, "val,oldval");
          localStorage.setItem(
            "val",
            JSON.parse(this.$Base64.decode(val.name))
          );
          //  this.search();
        }
        if (val.familyTreeId != undefined) {
          // this.familyTreeId = val.familyTreeId;
          localStorage.setItem(
            "familyTreeId",
            JSON.parse(this.$Base64.decode(val.familyTreeId))
          );
        }

        var params = new URLSearchParams(window.location.search);
        // 获取参数值
        this.familyTreeId = params.get("familyTreeId");
        this.name = params.get("name");

        this.title = JSON.parse(this.$Base64.decode(params.get("name")));
      },
    },
  },

  created() {
    // this.title = localStorage.getItem("val");

    // 轮播图
    this.getSlicedArray();

    // 通知
    this.getNotice();

    // 组织查询
    this.getOrganization();
    // 家族企业
    this.getFamilyFirm();
    // 宗亲分布
    this.getPatrilineal();
    // 名人
    this.getCelebrity();
    // 文化典故
    this.getCultural();
    // 功德
    this.getMerit();
  },

  methods: {
    // 通知
    noticeClick() {
      this.$router.push({
        path: "./ancestorsdetalis",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },
    // 企业
    enterpriseClick() {
      this.$router.push({
        path: "./enterprise",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },
    // 族谱
    genealogyClick() {
      this.$router.push({
        path: "./genealogys",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 家谱树
    ancestryClick() {
      this.$router.push({
        path: "./tree",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },
    // 更多
    moreClick() {
      this.$router.push({
        path: "./enterprise",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 点击家族企业跳转详情
    enterpriseDetails(item) {
      this.$router.push({
        path: "./notice",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
          id: item.id,
          selectedItem: "2",
        },
      });
    },

    familyDetails(item) {
      this.$router.push({
        path: "./notice",
        query: {
          // id: item.id,
          // selectedItem: "1",

          familyTreeId: this.familyTreeId,
          name: this.name,
          id: item.id,
          selectedItem: "1",
        },
      });
      console.log(item, "item");
    },

    // 通知

    async getNotice() {
      const { data: res } = await this.instance1.post("article/getList", {
        type: 1,
        currentPage: 1,
        pageSize: 5,
        familyTreeId: localStorage.getItem("familyTreeId"),
      });

      if (res.data.list.length >= 1) {
        this.noticeListsFirst = res.data.list[0];
        console.log(this.noticeListsFirst, " this.noticeListsFirst");
        this.noticeListsFirst.articleText =
          this.noticeListsFirst.articleText.replace(/<img[^>]*>/gi, "");
        this.noticeListsFirst.articleText =
          this.noticeListsFirst.articleText.replace(/<p>&nbsp;<\/p>/gi, "");

        this.noticeLists = res.data.list.slice(1, 5);
      }

      // this.meritList = res.data.list;
    },

    // 功德
    async getMerit() {
      const { data: res } = await this.instance1.post("article/getList", {
        type: 7,
        currentPage: 1,
        pageSize: 3,
        familyTreeId: localStorage.getItem("familyTreeId"),
      });
      this.meritList = res.data.list;
    },

    // 组织查询
    async getOrganization() {
      const { data: res } = await this.instance1.post(
        `roleList/${localStorage.getItem("familyTreeId")}`
      );
      this.organizationList = res.data;
    },

    // 家族企业
    async getFamilyFirm() {
      const { data: res } = await this.instance1.post("article/getList", {
        type: 3,
        currentPage: 1,
        pageSize: 4,
        familyTreeId: localStorage.getItem("familyTreeId"),
      });

      console.log(res.data, "res.data");

      this.familyFirmList = res.data.list.slice(0, 4);
    },

    // 宗亲分布
    async getPatrilineal() {
      const { data: res } = await this.instance1.get(
        `report/getFamilyTreeReport/${localStorage.getItem("familyTreeId")}`
      );
      this.patrilinealList = res.data;
    },
    // 名人
    async getCelebrity() {
      const { data: res } = await this.instance1.post("article/getList", {
        type: 4,
        currentPage: 1,
        pageSize: 6,
        familyTreeId: localStorage.getItem("familyTreeId"),
      });

      this.celebrityList = res.data.list;
    },
    // 文化典故
    async getCultural() {
      const { data: res } = await this.instance1.post("article/getList", {
        type: 5,
        currentPage: 1,
        pageSize: 3,
        familyTreeId: localStorage.getItem("familyTreeId"),
      });
      this.culturalList = res.data.list;
    },

    // 轮播图
    async getSlicedArray() {
      const { data: res } = await this.instance1.get("banner/showBanner", {
        params: {
          displayLocation: 2,
          familyTreeId: localStorage.getItem("familyTreeId"),
        },
      });

      this.slicedArrayList = res.data;
    },
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  /* height: 1200px; */
  height: 820px;
  display: flex;
  justify-content: center;
}
.con {
  width: 70%;
  height: 100%;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.bigbox {
  width: 100%;
  height: 350px;

  display: flex;
  justify-content: space-between;
}
.big-box {
  width: 100%;
  /* height: 360px; */
  height: 377px;
  display: flex;
  justify-content: space-between;
}
.banner {
  /* width: 47%; */
  width: 44%;
  height: 100%;
}
.new {
  width: 54%;
  height: 100%;
  background-image: linear-gradient(0deg, #ffffff 0%, #faf7d4 80%, #f4eea9 98%);
}
.a {
  position: absolute;
  top: 28px;
  left: 27px;
  color: #ffffff;
  font-size: 17px;
  transform: rotate(-45deg);
}
.banner > img {
  width: 100%;
  height: 100%;
}

.banner .picture {
  /* background-color: #27221e; */
  width: 100.2%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.banner .picture div {
  color: #fff;
  background-color: #000;
  opacity: 0.6;
}

.new-con {
  width: 100%;
  height: calc(100% - 100px);
  padding: 0px 43px 29px 23px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.new-con-text1 {
  word-wrap: break-word;
  height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  text-indent: 2em;
  line-height: 27px;
  color: #333333;
}
.new-con-text2 {
  font-size: 15px;
  line-height: 27px;
  color: #333333;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.nav {
  width: 32%;
  background-color: #ffffff;
  height: 100%;
}
.nav-top {
  width: 100%;
  height: 40px;
  color: #ffffff;
  background-image: linear-gradient(90deg, #c2252d 0%, #fdc300 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 26px;
  box-sizing: border-box;
}
.nav-con {
  width: 100%;
  height: calc(100% - 40px);
  padding: 20px;
  box-sizing: border-box;
}
.con1 {
  font-size: 17px;
}
.con1 > p {
  margin-top: 10%;
  line-height: 30px;
}
.con2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content2 {
  /* height: 132px; */
  height: 154px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #666666;
  font-size: 17px;
}
.content2 > img {
  width: 100%;
  height: 103px;
}
.con3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.content3 {
  width: 100%;
  height: 36px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}
.content4 {
  height: 132px;
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #666666;
  font-size: 17px;
}
.content5 {
  width: 100%;
  height: 32%;
  border-bottom: 2px solid #cccccc;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f8f8f8;
}
.active {
  border-bottom: 2px solid #bf1c29;
}
.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top .logo {
  /* width: 25%; */
  /* width: 14%; */
  /* width: 170px; */
  height: 73px;
  display: flex;
  align-items: center;
}
.logo > .logo-left > img {
  width: 100%;
  height: 100%;
}
.logo-left {
  width: 50px;
  height: 66px;
}
.logo-right {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.top .tab {
  /* width: 40%; */
  width: 24%;
  /* width: 30%; */
  display: flex;
  justify-content: space-between;
}
.top .tab .box {
  cursor: pointer;
  height: 35px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.e {
  /* width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; */
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}
.f {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ewm {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;

  font-size: 15px;
}
.ewm > img {
  width: 125px;
  height: 125px;
  margin-left: 10px;
}
.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
  /* display: flex; */
  /* justify-content: space-around; */
}
</style>
