import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home.vue";
import SurnameList from "../views/surnamelist.vue";
import SurnameDetails from "../views/surnameDetails.vue";
import Ancestors from "../views/ancestors.vue";
import About from "../views/about.vue";
import Ancestorsdetalis from "../views/ancestorsdetalis.vue";
import Download from "../views/download.vue";
import EditorialBoard from "../views/editorial-board.vue";
import Enterprise from "../views/enterprise.vue";
import Genealogy from "../views/genealogy.vue";
import Genealogys from "../views/genealogys.vue";
import Notice from "../views/notice.vue";
import NoticeList from "../views/noticelist.vue";
import Surname from "../views/surname.vue";
import Tree from "../views/tree.vue";
import Celebrity from "../views/celebrity.vue";
import Culture from "../views/culture.vue";
import Merit from "../views/merit.vue";
import GenealogyDetails from "../views/genealogyDetails.vue";

import Preview from "../views/preview.vue";

import PartyBuilding from "../views/partyBuilding.vue";

import PartyBuildingDetails from "../views/partyBuildingDetails.vue";

import getPageTitle from "@/utils/get-page-title";
import decode from "../utils/base64";
Vue.use(VueRouter);
Vue.prototype.$Base64 = {
  decode: decode,
};
const routes = [
  {
    path: "/",
    // name: 'home',
    component: HomeView,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/surnameList",
    component: SurnameList,
    meta: {
      title: "百家姓",
    },
  },
  {
    path: "/surnameDetails",
    component: SurnameDetails,
    meta: {
      title: "百家姓",
    },
  },
  {
    path: "/ancestors",
    component: Ancestors,
    meta: {
      title: "寻亲问祖",
    },
  },
  {
    path: "/about",
    component: About,
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/ancestorsdetalis",
    component: Ancestorsdetalis,
  },
  {
    path: "/download",
    component: Download,
    meta: {
      title: "下载中心",
    },
  },
  {
    path: "/editorialBoard",
    name: "tiaozhuan",
    component: EditorialBoard,
  },
  {
    path: "/enterprise",
    component: Enterprise,
  },
  {
    path: "/genealogy",
    component: Genealogy,
    meta: {
      title: "家谱库",
    },
  },
  {
    path: "/genealogys",
    component: Genealogys,
  },
  {
    path: "/notice",
    component: Notice,
  },

  {
    path: "/noticelist",
    component: NoticeList,
    meta: {
      title: "寻亲问祖",
    },
  },

  {
    path: "/surname",
    component: Surname,
    meta: {
      title: "关于我们",
    },
  },

  {
    path: "/tree",
    component: Tree,
  },

  {
    path: "/celebrity",
    component: Celebrity,
  },
  {
    path: "/culture",
    component: Culture,
  },
  {
    path: "/merit",
    component: Merit,
  },

  {
    path: "/genealogyDetails",
    component: GenealogyDetails,
  },

  {
    path: "/preview",
    component: Preview,
  },

  {
    path: "/partyBuilding",
    component: PartyBuilding,
    meta: {
      title: "党建学习",
    },
  },

  {
    path: "/partyBuildingDetails",
    component: PartyBuildingDetails,
    meta: {
      title: "党建学习",
    },
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  if (
    to.path == "/editorialBoard" ||
    to.path == "/ancestorsdetalis" ||
    to.path == "/enterprise" ||
    to.path == "/genealogys" ||
    to.path == "/tree" ||
    to.path == "/celebrity" ||
    to.path == "/culture" ||
    to.path == "/merit" ||
    to.path == "/notice"
  ) {
    // console.log("-----------------------------------------------");
    // document.title = localStorage.getItem("val");

    var params = new URLSearchParams(window.location.search);
    // console.log(
    //   "🚀 ~ router.beforeEach ~  :",
    //   JSON.parse(this.$decodeBase64(params.get("name")))
    // );
    // const decodedParam = Vue.prototype.$Base64.decode(params.get("name"));
    // console.log("🚀 ~ router.beforeEach ~ decodedParam:", decodedParam);

    document.title = JSON.parse(
      Vue.prototype.$Base64.decode(params.get("name"))
    );
    next();
    return;
  }

  document.title = getPageTitle(to.meta.title);
  // console.log(to, "to");

  next();
});

export default router;
