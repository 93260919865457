<template>
  <ul>
    <li v-for="(item, index) in data" :key="index">
      <div class="qq" @click="details(item)">
        <div class="qa">
          <!-- <img
            src="https://img1.baidu.com/it/u=50627749,1570510824&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800"
            alt=""
          /> -->

          <img
            :src="item.detail.avatar"
            alt=""
            v-show="item.detail.avatar != null"
          />
          <img
            src="@/assets/png/bwh/male.png"
            alt=""
            v-show="item.detail.sex == 1 && item.detail.avatar == null"
          />
          <img
            src="@/assets/png/bwh/female.png"
            alt=""
            v-show="item.detail.sex == 2 && item.detail.avatar == null"
          />
          <p style="font-size: 18px; margin-top: 10px; font-weight: 500">
            {{ item.title }}
          </p>
          <div>
            <i
              class="el-icon-male"
              style="font-size: 18px; color: #acd5f2"
              v-show="item.detail.sex == 1"
            ></i>
            <i
              class="el-icon-female"
              style="font-size: 18px; color: red"
              v-show="item.detail.sex == 2"
            ></i>
            <img
              style="width: 12px; height: 15px"
              src="../../assets/png/bwh/live.png"
              alt=""
              v-show="item.detail.live == 2"
            />
          </div>
          <div class="qb"></div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 1"
          >
            长子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 2"
          >
            次子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 3"
          >
            三子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 4"
          >
            四子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 5"
          >
            五子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 6"
          >
            六子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 7"
          >
            七子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 8"
          >
            八子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 9"
          >
            九子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 1 && item.detail.sort == 10"
          >
            十子
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 1"
          >
            长女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 2"
          >
            次女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 3"
          >
            三女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 4"
          >
            四女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 5"
          >
            五女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 6"
          >
            六女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 7"
          >
            七女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 8"
          >
            八女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 9"
          >
            九女
          </div>
          <div
            class="qc"
            v-show="item.detail.sex == 2 && item.detail.sort == 10"
          >
            十女
          </div>
          <div class="qd">第{{ item.detail.generationNo }}世</div>
        </div>
      </div>
      <Tree
        v-if="item.children && item.children.length > 0"
        :data="item.children"
      />
    </li>
  </ul>
</template>

<script>
import Tree from "./tree-children.vue";
import EventBus from "@/config/event";
// import treeDialog from "./treeDialog.vue";

export default {
  name: "Tree",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      //   childrenDialogVisible: false,
    };
  },
  components: { Tree },

  created() {},

  methods: {
    details(item) {
      if (item.detail.spouseList && item.detail.spouseList.length == 0) {
        return this.$message({
          message: "未添加配偶",
          type: "warning",
        });
      }

      EventBus.$emit("aMsg", true, item.id);
    },
  },
};
</script>

<style scoped>
.active {
  border-bottom: 2px solid #bf1c29;
}

.surname {
  width: 100%;
  height: 1000px;
  background-color: #fff;

  z-index: 99;
}
.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}
.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}
.tree {
  width: 100%;
  height: calc(100% - 52px);
  overflow: auto;
}

#main2 {
  width: 100%;
  height: 500px;
  z-index: 99999999999;
}
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f8f8f8;
}
/* .top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top .logo {
  width: 170px;
  height: 73px;
}
.logo > img {
  width: 100%;
  height: 100%;
} */

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top .logo {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
}
.logo > .logo-left > img {
  width: 100%;
  height: 100%;
}
.logo-left {
  width: 50px;
  height: 66px;
}
.logo-right {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.top .tab {
  /* width: 612px; */
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.top .tab .box {
  /* font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: 500; */
  cursor: pointer;
  height: 35px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}
.banner {
  width: 100%;
  height: 400px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: calc(1996px - 645px - 161px);
  position: relative;
}
.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}
.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}
.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc(1996px - 645px - 161px);
  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}
.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}
.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}
.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}

.tree ul {
  display: flex;
  margin: 0;
  padding: 20px 0 0;
  position: relative;
  transition: all 0.5s;
}
.tree li {
  position: relative;
  padding: 20px 5px 0;
  text-align: center;
  list-style-type: none;
  transition: all 0.5s;
}
.tree li::before,
.tree li::after {
  position: absolute;
  top: 0;
  right: calc(50% - 1px);
  width: calc(50% + 1px);
  height: 20px;
  border-top: 2px solid #d6c0a8;
  content: "";
}
.tree li::after {
  right: auto;
  left: calc(50% - 1px);
  border-left: 2px solid #d6c0a8;
}
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}
.tree li:only-child {
  padding-top: 0;
}
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
.tree li:last-child::before {
  border-right: 2px solid #d6c0a8;
}
.tree ul ul::before {
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  width: 0;
  height: 20px;
  border-left: 2px solid #d6c0a8;
  content: "";
}
.qq {
  display: inline-block;
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 120px;
  height: 200px;
}
.qa {
  color: #666;
  width: 120px;
  height: 170px;
  padding: 10px 10px 2px 10px;
  border: 1px dashed #ddccb9;
  font-size: 12px;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
}
.qa img {
  width: 90px;
  height: 100px;
}
.qb {
  width: 70px;
  height: 25px;
  background-color: #fff;
  background: url(../../assets/png/bwh/sort.png);
  background-size: 100% 100%;
  position: absolute;
  top: 95px;
  left: 25px;
  z-index: 9;
}
.qc {
  width: 70px;
  height: 25px;
  /* background-color: #fff; */
  text-align: center;
  line-height: 25px;
  font-size: 17px;
  color: #999;
  position: absolute;
  top: 95px;
  left: 25px;
  z-index: 99;
}
.qd {
  position: absolute;
  bottom: -25px;
  left: 35px;
  font-size: 16px;
  color: #999;
}
</style>
