<template>
  <div>
    <div class="top">
      <div class="logo">
        <div class="logo-left">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="logo-right">
          <p style="font-size: 30px; font-weight: bold">{{ title }}</p>
          <p style="font-size: 20px">www.zhzqw.cn</p>
        </div>
      </div>
      <div class="tab">
        <div class="box" @click="homePage">首页</div>
        <div class="box" @click="noticeClick">通知</div>
        <!-- <div class="box">组织</div> -->
        <div class="box" @click="enterpriseClick">企业</div>
        <div class="box actives">族谱</div>
        <div class="box" @click="ancestryClick">家谱树</div>
        <!-- <div class="box">宗亲分布</div> -->
        <!-- <div class="box" @click="$router.push('./celebrity')">名人</div>
        <div class="box" @click="$router.push('./culture')">文化典故</div>
        <div class="box" @click="$router.push('./merit')">功德</div> -->
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="mune">
          <div class="mune-top">目录</div>
          <div
            class="mune-item"
            v-for="(item, index) in familyFirmList"
            :key="index"
            style="z-index: 9999; cursor: pointer"
            @click="letterClcik(item)"
          >
            {{ item.label }}
            <div
              :class="[item.label == isActive ? 'active' : '']"
              style="z-index: 9999999"
            ></div>
          </div>
          <!-- <div class="mune-item">
            下册
            <div class="box3"></div>
          </div> -->
        </div>
        <div class="surname">
          <div class="top2">族谱</div>
          <div
            style="
              width: 100%;
              height: calc(100% - 52px - 131px);
              padding: 50px 67px 131px 40px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
            "
          >
            <div
              class="con1"
              v-for="(item, index) in childrenList"
              :key="index"
            >
              <p>{{ item.label }}</p>
              <!-- <a :href="item.value" target="_blank" style="z-index: 9999"
                >预览</a
              > -->
              <router-link
                style="cursor: pointer; z-index: 999"
                :to="{
                  path: './preview',
                  query: {
                    pdf: item.value,
                  },
                }"
                target="_blank"
              >
                预览
              </router-link>
            </div>
            <!-- <div class="con1">
              <p>卷一</p>
              <p>预览</p>
            </div>
            <div class="con1">
              <p>卷一</p>
              <p>预览</p>
            </div>
           
          
            <div class="con1">
              <p>卷一</p>
              <p>预览</p>
            </div>
            <div class="con1">
              <p>卷一</p>
              <p>预览</p>
            </div> -->
          </div>
          <div class="bottom">
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              style="z-index: 999"
              @current-change="handleSizeChange"
            >
            </el-pagination> -->
            <!-- <div class="box2">首页</div>
            <div class="box2">上一页</div>
            <div class="box2">1</div>
            <div class="box2">下一页</div>
            <div class="box2">尾页</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 寻亲问祖
      menusList: [],
      title: "",
      familyFirmList: [],
      isActive: "上册",

      childrenList: [],
      pageSize: 12,
      // 总数
      total: 0,
      familyTreeId: "",

      name: "",
    };
  },

  created() {
    var params = new URLSearchParams(window.location.search);
    // 获取参数值
    this.familyTreeId = params.get("familyTreeId");
    this.name = params.get("name");
    this.title = JSON.parse(this.$Base64.decode(params.get("name")));
    // 族谱
    this.getFamilyFirm();
  },
  methods: {
    // 首页
    homePage() {
      this.$router.push({
        path: "./editorialBoard",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 通知
    noticeClick() {
      this.$router.push({
        path: "./ancestorsdetalis",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 企业
    enterpriseClick() {
      this.$router.push({
        path: "./enterprise",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 家谱树
    ancestryClick() {
      this.$router.push({
        path: "./tree",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    handleSizeChange(val) {
      this.currentPage = val;
      // 百家姓
      this.getFamilyFirm();

      console.log(val, "val");
    },

    letterClcik(item) {
      console.log("-------------");
      this.isActive = item.label;
      this.childrenList = item.children;
      // console.log(item.children, "item.children");
      // this.isActive = e.target.innerText;
      // let bbb = this.resData.filter((it, idx) => {
      //   // console.log(item.firstLetter, 'item.firstLetter');
      //   // console.log(e.target.innerText, 'e.target.innerText');

      //   return it.firstLetter == e.target.innerText;
      // });
      // //   console.log(bbb, "bbb");
      // this.slicedArrayList = bbb;
    },

    // 族谱

    async getFamilyFirm() {
      var params = new URLSearchParams(window.location.search);
      // 获取参数值
      var familyTreeId = params.get("familyTreeId");

      const { data: res } = await this.instance1.get(
        `document/getUrl/${JSON.parse(this.$Base64.decode(familyTreeId))}`
      );
      if (res.code == 200) {
        this.familyFirmList = JSON.parse(res.data.url);
        console.log(
          "🚀 ~ getFamilyFirm ~ this.familyFirmList:",
          this.familyFirmList
        );

        this.familyFirmList.forEach((item, index) => {
          if (item.label == "下册") {
            this.familyFirmList.splice(index, 1);
            // this.childrenList = item.children;
          }
        });

        this.familyFirmList.forEach((item, index) => {
          if (item.label == "上册") {
            // this.familyFirmList.splice(index, 1);
            item.children.pop();
            this.childrenList = item.children;
            console.log(item.children, "item.children");
          }
        });

        console.log(this.familyFirmList, "this.familyFirmList");
      }
    },
  },
};
</script>

<style scoped>
.actives {
  border-bottom: 2px solid #bf1c29;
}

.surname {
  width: 80%;
  height: 1000px;
  background-color: #fff;
}

.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}

.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}

.mune {
  width: 15%;
  height: 150px;
  background-color: #fff;
}

.mune-top {
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, rgb(194, 37, 45), rgb(253, 195, 0));
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

.mune-item {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 15px;
  position: relative;
}

.mune-item div {
  width: 5px;
  height: 17px;
  position: absolute;
  top: 16px;
  left: 25px;
}

.active {
  background-color: rgb(194, 37, 45);
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f8f8f8;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  /* width: 14%; */
  height: 73px;
  display: flex;
  align-items: center;
}

.logo > .logo-left > img {
  width: 100%;
  height: 100%;
}

.logo-left {
  width: 50px;
  height: 66px;
}

.logo-right {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.top .tab {
  /* width: 50%; */
  width: 24%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  /* font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: 500; */
  cursor: pointer;
  height: 35px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc(1996px - 645px - 161px);
  position: relative;
}

.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}

.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}

.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc(1996px - 645px - 161px);

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
