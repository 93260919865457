<template>
  <div>
    <div class="top">
      <div class="logo">
        <img src="@/assets/png/home/zqw.png" alt="" />
      </div>
      <div class="tab">
        <div class="box" @click="toHome()">首页</div>
        <div class="box" @click="toHundred()">百家姓</div>
        <div class="box" @click="toAncestors()">寻亲问祖</div>
        <div class="box acitves" @click="toGenealogy()">家谱库</div>
        <div class="box" @click="toDownload()">下载中心</div>
        <div class="box" @click="toAbout()">关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="mune">
          <div class="mune-top">族谱姓氏</div>
          <div
            class="mune-item"
            v-for="(item, index) in genealogyList"
            :key="index"
            @click="genealogyClick(item)"
          >
            {{ item.title }}
            <div :class="[item.id == isActive ? 'active' : '']"></div>
          </div>
          <!-- <div class="mune-item">
              张氏族谱
              <div class=""></div>
            </div>
            <div class="mune-item">
              张氏族谱
              <div class=""></div>
            </div>
            <div class="mune-item">
              张氏族谱
              <div class=""></div>
            </div>
            <div class="mune-item">
              张氏族谱
              <div class=""></div>
            </div> -->
        </div>
        <div class="surname">
          <div class="top2">家谱库</div>
          <div
            class="main"
            style="
              width: 100%;
              /* height: calc(100% - 52px - 131px); */
              padding: 50px 67px 131px 40px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
            "
          >
            <div class="con1" v-for="(item, index) in childList" :key="index">
              <p @click="genealogyDetails">{{ item.title }}</p>
              <p>阅读：{{ item.hits }}</p>
            </div>
            <!-- <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div> -->
          </div>
          <!-- <div class="bottom">
              <!== <el-pagination
                background
                layout="prev, pager, next"
                :total="1000"
                style="z-index: 999"
              >
              </el-pagination> ==>
            </div> -->
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: "1",
      // 寻亲问祖
      menusList: [],
      // 百家姓
      slicedArrayList: [],
      // 定义字体对象
      iconsObj: {
        125: "iconfont icon-user",
        103: "iconfont icon-tijikongjian",
        101: "iconfont icon-shangpin",
        102: "iconfont icon-danju",
        145: "iconfont icon-baobiao",
      },
      //激活的菜单项目
      activePath: "",
      // 控制左侧菜单列表的折叠布尔值
      isCollapse: false,
      genealogyList: [],

      childList: [],
    };
  },

  watch: {
    // 监视搜索词变化
    "$route.query": {
      immediate: true,
      handler(val, oldval) {
        console.log(val, oldval, "val, oldval");
        this.isActive = val.id;
        // if (val.name != undefined) {
        //   // console.log(val, oldval, "val,oldval");
        //   localStorage.setItem("val", val.name);

        //   //  this.search();
        // }
        // if (val.familyTreeId != undefined) {
        //   // this.familyTreeId = val.familyTreeId;
        //   localStorage.setItem("familyTreeId", val.familyTreeId);
        // }

        // this.title = localStorage.getItem("val");
      },
    },
  },

  created() {
    // 家谱库
    this.getGenealogy();
  },
  methods: {
    // 详情页
    genealogyDetails() {
      this.$router.push({
        path: "./genealogyDetails",
      });
    },

    genealogyClick(item) {
      this.childList = item.childList;
      this.isActive = item.id;
    },

    // 家谱库
    async getGenealogy() {
      const { data: res } = await this.instance1.get("library/getList");
      console.log(res, "res");
      this.genealogyList = res.data;

      res.data.forEach((item, index) => {
        if (this.isActive == item.id) {
          this.childList = item.childList;
        }
      });
    },

    // 首页
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    // 百家姓
    toHundred() {
      this.$router.push({
        path: "./surnameList",
      });
    },
    // 寻亲问祖
    toAncestors() {
      this.$router.push({
        path: "./ancestors",
      });
    },
    // 家谱库
    toGenealogy() {
      //   this.$router.push({
      //     path: "./genealogy",
      //   });
      // location.href = "./genealogy.html";
    },
    // 下载中心
    toDownload() {
      this.$router.push({
        path: "./download",
      });
      // location.href = "./download.html";
    },
    // 关于我们
    toAbout() {
      // this.$router.push({
      //   path: "./about",
      // });
      // location.href = "./about.html";
    },
  },
};
</script>

<style scoped>
.surname {
  width: 80%;
  /* height: 1000px; */
  background-color: #fff;
}

.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}

.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}

.mune {
  width: 15%;
  height: 800px;
  background-color: #fff;
}

.mune-top {
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, rgb(194, 37, 45), rgb(253, 195, 0));
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

.mune-item {
  cursor: pointer;
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 15px;
  position: relative;
  z-index: 999;
}

.mune-item div {
  width: 5px;
  height: 17px;
  position: absolute;
  top: 16px;
  left: 25px;
}

.active {
  background-color: rgb(194, 37, 45);
}

.test0 {
  background-color: rgb(194, 37, 45);
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f8f8f8;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  width: 170px;
  height: 73px;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.top .tab {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.acitves {
  border-bottom: 2px solid #cd4f4b;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  /* height: calc(1996px - 645px - 161px); */
  position: relative;
}

.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  /* height: calc(1996px - 645px - 161px); */
  display: flex;
}

.content .content-con .left {
  width: 50%;
  /* height: calc(1996px - 645px - 161px - 280px); */
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}

.content .content-con .right {
  width: 50%;
  /* height: calc(1996px - 645px - 161px - 280px); */
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  /* height: calc(1996px - 645px - 161px); */

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
