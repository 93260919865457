<template>
  <div>
    <div class="PDFFlip" id="PDFF" style=""></div>
  </div>
</template>

<!-- <script src="./pflip/js/libs/jquery.min.js" type="text/javascript"></script> -->
<!-- <script src="./pflip/js/pdf-flip.js" type="text/javascript"></script>  -->
<!-- <link href="./pflip/css/pdfflip.css" rel="stylesheet" type="text/css" /> -->

<script>
// import './pflip/js/libs/jquery.min.js';
// import './pflip/js/pdf-flip.js';
export default {
  data() {
    return {
      pdf: undefined,
    };
  },

  watch: {
    // // 监视搜索词变化
    "$route.query": {
      immediate: true,
      // deep: true,
      handler(val, oldval) {
        console.log(val, oldval, "val, oldval");
        this.pdf = val.pdf;
      },
    },
  },

  mounted() {
    window.option_PDFF = {
      source: this.pdf,
      enableSound: false,
      autoPlayStart: false,
      autoPlayDuration: 3000,
      openPage: 1,
      scrollWheel: false,
      // transparent: true,
      // backgroundImage: '/pdf-flip/examples/dest_bg.jpg',
      hideControls:
        "outline,share,download,thumbnail,sound,play,pause,fullScreen",
      pageSize: 0,
      pageMode: 2,
      singlePageMode: 2,
      zoomRatio: 1.5,
      text: {
        gotoFirstPage: "首页",
        gotoLastPage: "尾页",
        nextPage: "下一页",
        previousPage: "上一页",
        zoomIn: "放大",
        zoomOut: "缩小",
        play: "播放",
        pause: "暂停",
        toggleSound: "声音",
        toggleFullscreen: "全屏",
      },
    };

    window.pdfflipLocation = "/pflip/";
  },
};
</script>
