<template>
  <div>
    <div class="top">
      <div class="logo">
        <div class="logo-left">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="logo-right">
          <p style="font-size: 30px; font-weight: bold">{{ title }}</p>
          <p style="font-size: 20px">www.zhzqw.cn</p>
        </div>
      </div>
      <div class="tab">
        <div @click="homePage">首页</div>
        <div :class="{ active: selectedItem == 1 }" @click="noticeClick">
          通知
        </div>
        <!-- <div >组织</div> -->
        <div :class="{ active: selectedItem == 2 }" @click="enterpriseClick">
          企业
        </div>
        <div @click="genealogyClick">族谱</div>
        <div @click="ancestryClick">家谱树</div>
        <!-- <div
          :class="{ active: selectedItem == 3 }"
          @click="$router.push('./celebrity')"
        >
          名人
        </div>
        <div
          :class="{ active: selectedItem == 4 }"
          @click="$router.push('./culture')"
        >
          文化典故
        </div>
        <div
          :class="{ active: selectedItem == 5 }"
          @click="$router.push('./merit')"
        >
          功德
        </div> -->
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <!-- <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div> -->
      <div class="con">
        <!-- <div class="top1">
            <img src="@/assets/png/home/smallLogo.png" alt="" />
            <p>下载中心</p>
            <div class="xian2"></div>
            <div class="more">+更多</div>
          </div> -->
        <div class="surname">
          <div class="top2">详情</div>
          <div
            style="
              width: 100%;
              height: calc(100% - 52px - 131px);
              padding: 50px 40px 131px 40px;
              box-sizing: border-box;
            "
          >
            <p
              style="
                text-align: center;
                font-size: 23px;
                font-weight: bold;
                margin-bottom: 50px;
              "
            >
              {{ familyFirmList.title }}
            </p>
            <div class="surname-font" v-html="familyFirmList.articleText">
              <!-- 众亲网是中国第一家以“姓氏”为纽带，以“族谱”为表现方式的专业性门户网站，致力于打造中华家谱网络版，搭建“全球宗亲一线牵”的全网平台。 -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 寻亲问祖
      menusList: [],
      title: "",

      familyFirmList: [],
      currentPage: 1,
      pageSize: 12,
      // 总数
      total: 0,

      selectedItem: "",
      // familyFirmList:{}

      familyTreeId: "",

      name: "",
    };
  },

  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(val, oldval) {
        console.log(val, oldval, "val, oldval");
        this.selectedItem = val.selectedItem;
        this.getFamilyFirm();
        if (val.id != undefined) {
          // console.log(val, oldval, "val,oldval");
          // 家族企业
          //  this.search();
        }
      },
    },
  },

  created() {
    var params = new URLSearchParams(window.location.search);
    // 获取参数值
    this.familyTreeId = params.get("familyTreeId");
    this.name = params.get("name");
    this.title = JSON.parse(this.$Base64.decode(params.get("name")));
  },
  methods: {
    // 首页
    homePage() {
      this.$router.push({
        path: "./editorialBoard",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 通知
    noticeClick() {
      this.$router.push({
        path: "./ancestorsdetalis",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 企业
    enterpriseClick() {
      this.$router.push({
        path: "./enterprise",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 族谱
    genealogyClick() {
      this.$router.push({
        path: "./genealogys",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 家谱树
    ancestryClick() {
      this.$router.push({
        path: "./tree",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 家族企业
    async getFamilyFirm() {
      const { data: res } = await this.instance1.get(
        `article/findDetail/${this.$route.query.id}`
      );
      this.familyFirmList = res.data;
      console.log(res.data, "res.data");

      // this.familyFirmList = res.data.list;
      // this.total = res.data.total;
    },
  },
};
</script>

<style scoped>
.surname {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}
.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}
.surname-font {
  /* word-break: break-all; */
  word-wrap: break-word;
  font-family: MicrosoftYaHei;
  font-size: 17px;
  line-height: 53px;
  color: #333333;
  text-indent: 2em;
}

* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f8f8f8;
}

.active {
  border-bottom: 2px solid #bf1c29;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top .logo {
  /* width: 14%; */
  height: 73px;
  display: flex;
  align-items: center;
}
.logo > .logo-left > img {
  width: 100%;
  height: 100%;
}
.logo-left {
  width: 50px;
  height: 66px;
}
.logo-right {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.top .tab {
  /* width: 50%; */
  width: 24%;
  display: flex;
  justify-content: space-between;
}
.top .tab div {
  /* font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: 500; */
  cursor: pointer;
  height: 35px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.banner {
  width: 100%;
  height: 400px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  /* height: calc(1996px - 645px - 161px); */
  position: relative;
}
.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}
.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}
.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  /* height: calc(1996px - 645px - 161px); */

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}
.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}
.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}
.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  margin-top: 80px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
