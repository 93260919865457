import { render, staticRenderFns } from "./App.vue?vue&type=template&id=ae7ef892"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=ae7ef892&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports