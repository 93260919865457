<template>
  <div>
    <div class="top">
      <div class="logo">
        <img src="@/assets/png/home/zqw.png" alt="" />
      </div>
      <div class="tab">
        <div class="box" @click="toHome()">首页</div>
        <div class="box" @click="toHundred()">百家姓</div>
        <div class="box" @click="toAncestors()">寻亲问祖</div>
        <div class="box" @click="toGenealogy()">家谱库</div>
        <div class="box acitves">党建学习</div>
        <div class="box" @click="toDownload()">下载中心</div>
        <div class="box" @click="toAbout()">关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>

    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="surname">
          <div class="top2">党建学习</div>
          <div
            style="
              width: 100%;
              height: calc(100% - 52px - 131px);
              padding: 50px 67px 131px 40px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
            "
          >
            <div class="con1" v-for="(item, index) in articleList" :key="index">
              <p
                style="cursor: pointer; z-index: 99999"
                @click="familyDetails(item)"
              >
                {{ item.title }}
              </p>
              <p>阅读：{{ item.hits }}</p>
            </div>
            <!-- <div class="con1">
              <p>安徽合肥蜀山张氏编委会</p>
         
            <div class="con1">
              <p>安徽合肥蜀山张氏编委会</p>
              <p>阅读：100</p>
            </div> -->
          </div>
          <div class="bottom">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              style="z-index: 999"
              class="custom-pagination"
              @current-change="handleSizeChange"
            >
            </el-pagination>

            <!-- <div class="box2">首页</div>
            <div class="box2">上一页</div>
            <div class="box2">1</div>
            <div class="box2">下一页</div>
            <div class="box2">尾页</div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <div style="text-align: left">
            版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司
          </div>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "partyBuilding",
  data() {
    return {
      articleList: [],
      title: "",
      slicedArrayList: [],

      currentPage: 1,
      pageSize: 12,
      // 总数
      total: 0,

      familyTreeId: "",

      name: "",
    };
  },
  created() {
    // // 百家姓
    this.getArticleList();
  },
  methods: {
    familyDetails(item) {
      this.$router.push({
        path: "./partyBuildingDetails",
        query: {
          id: item.id,
        },
      });
      console.log(item, "item");
    },

    // 切换分页
    handleSizeChange(val) {
      this.currentPage = val;
      // 百家姓
      this.getArticleList();

      console.log(val, "val");
    },

    // 党建学习

    async getArticleList() {
      const { data: res } = await this.instance1.post("article/getList", {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: 9,
      });
      console.log(res, "res");
      this.articleList = res.data.list;
    },

    // 首页
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    // 百家姓
    toHundred() {
      this.$router.push({
        path: "./surnameList",
      });
    },
    toAncestors() {
      this.$router.push({
        path: "./ancestors",
      });
    },
    // 家谱库
    toGenealogy() {
      this.$router.push({
        path: "./genealogy",
      });
      // location.href = "./genealogy.html";
    },

    // 下载中心
    toDownload() {
      this.$router.push({
        path: "./download",
      });
      // location.href = "./download.html";
    },
    toAbout() {
      this.$router.push({
        path: "./about",
      });
      // location.href = "./about.html";
    },
  },
};
</script>

<style scoped>
::v-deep .custom-pagination .el-pager .active {
  /* 设置自定义的页码背景颜色 */
  background-color: #bf1b28 !important;
}

::v-deep .custom-pagination .el-pager li:hover {
  color: #bf1b28 !important;
}

::v-deep .custom-pagination .el-pager .active:hover {
  /* 设置自定义的页码背景颜色 */
  color: #fff !important;
}

.surname {
  width: 100%;
  height: 1000px;
  background-color: #fff;
}

.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}

.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}

* {
  margin: 0;
  padding: 0;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  width: 170px;
  height: 73px;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.top .tab {
  width: 38%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.acitves {
  border-bottom: 2px solid #cd4f4b;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc(1996px - 645px - 161px);
  position: relative;
}

.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}

.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}

.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc(1996px - 645px - 161px);

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.con .font {
  width: 60%;
  height: 711px;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}

.xian {
  width: 85%;
  height: 1px;
  background-color: #dddddd;
}

.xian1 {
  width: 65%;
  height: 1px;
  background-color: #dddddd;
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  cursor: pointer;
  color: #c2c2c2;
}

.card {
  width: 35%;
  height: 711px;
}

.zi {
  cursor: pointer;
  width: 70px;
  height: 70px;
  margin-right: 6.5px;
  background: url(@/assets/png/home/grid.png) no-repeat;
  background-size: 100% 100%;
  font-size: 24px;
  text-align: center;
  line-height: 70px;
  margin-top: 10px;
  z-index: 999;
}

.cards {
  width: 100%;
  position: relative;
  height: 150px;
  font-size: 18px;
  font-weight: normal;

  color: #616161;
}

.cards img {
  width: 100%;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
}

.co {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(@/assets/png/home/3.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.le {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/png/home/2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.ri {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/png/home/4.png);
  background-repeat: no-repeat;
  background-size: 110% 130%;
  background-position: -37px -40px;
}

.a {
  width: 100%;
  height: 20%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 14px;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

.b {
  margin-top: 10px;
  padding-right: 40px;
}

.c {
  margin-top: 10px;
  padding-right: 40px;
}

.d {
  width: 211px;
  height: 21px;
  background-image: url(@/assets/png/home/5.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 22;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}

.item {
  width: 14px;
  height: 14px;
  background-image: url(@/assets/png/home/time.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

body {
  background-color: #f8f8f8;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.download {
  width: 100%;
  height: 246px;
  display: flex;
  justify-content: space-around;
}

.box1 {
  width: 31%;
  height: 226px;
  background-image: url(@/assets/png/home/roundedRectangle.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.qRCode {
  width: 31%;
  height: 226px;
  background-color: #fff;
  border-radius: 28px;
  /* background-image: url(@/assets/png/home/roundedRectangle.png); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  z-index: 99999 !important;
}

.images {
  cursor: pointer;
  z-index: 99999 !important;
}

/* .top2 {
    width: 80%;
    height: 60px;
    display: flex;
    justify-content: space-between;
} */

.left1 {
  width: 60px;
  height: 60px;
}

.right1 {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}

.con1 img {
  width: 100%;
  height: 100%;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
