<template>
  <div>
    <div class="top">
      <div class="logo">
        <div class="logo-left">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="logo-right">
          <p style="font-size: 30px; font-weight: bold">{{ title }}</p>
          <p style="font-size: 20px">www.zhzqw.cn</p>
        </div>
      </div>

      <div class="tab">
        <div class="box" @click="homePage">首页</div>
        <div class="box" @click="noticeClick">通知</div>
        <!-- <div class="box">组织</div> -->
        <div class="box" @click="enterpriseClick">企业</div>
        <div class="box" @click="genealogyClick">族谱</div>
        <div class="box active">家谱树</div>
        <!-- <div class="box">宗亲分布</div> -->
        <!-- <div class="box" @click="$router.push('./celebrity')">名人</div>
        <div class="box" @click="$router.push('./culture')">文化典故</div>
        <div class="box" @click="$router.push('./merit')">功德</div> -->
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="surname">
          <div class="top2">家谱树</div>
          <div class="tree">
            <!-- 组件组件 -->
            <Tree :data="fullSpectrumList" />
            <tree-dialog />
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tree from "./components/tree-children.vue";
import treeDialog from "./components/treeDialog.vue";

export default {
  components: {
    Tree,
    treeDialog,
  },
  data() {
    return {
      dialogVisible: false,
      title: "",

      fullSpectrumList: [],
      childrenDialogVisible: false,

      revisedVisible: true,

      diguishuzu: [],

      life: 1,
      familyTreeId: "",

      name: "",
    };
  },

  created() {
    var params = new URLSearchParams(window.location.search);
    // 获取参数值
    this.familyTreeId = params.get("familyTreeId");
    this.name = params.get("name");
    this.title = JSON.parse(this.$Base64.decode(params.get("name")));
    // 查询全谱
    this.getFullSpectrum();
  },
  methods: {
    // 首页
    homePage() {
      this.$router.push({
        path: "./editorialBoard",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 通知
    noticeClick() {
      this.$router.push({
        path: "./ancestorsdetalis",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 族谱
    genealogyClick() {
      this.$router.push({
        path: "./genealogys",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 企业
    enterpriseClick() {
      this.$router.push({
        path: "./enterprise",
        query: {
          familyTreeId: this.familyTreeId,
          name: this.name,
        },
      });
    },

    // 关闭指令计划弹窗
    revisedVisibleChange(val) {
      this.revisedVisible = val;
    },

    // 查询全谱

    async getFullSpectrum() {
      var params = new URLSearchParams(window.location.search);
      // 获取参数值
      var familyTreeId = params.get("familyTreeId");
      const { data: res } = await this.instance1.get(
        `familyMembers/getTotalTree/${JSON.parse(
          this.$Base64.decode(familyTreeId)
        )}`
      );

      // const flattenedData = this.flattenTreeData(res.data);
      // console.log(flattenedData, "flattenedData");

      this.life = 1;
      this.flattenTreeData(res.data, this.life);

      this.life = this.life - 4;

      this.ceshi(res.data);

      this.fullSpectrumList = res.data;
      // console.log(
      //   "🚀 ~ getFullSpectrum ~ this.fullSpectrumList:",
      //   this.fullSpectrumList
      // );
    },

    ceshi(val) {
      val.forEach((element) => {
        if (element.detail.generationNo > this.life) {
          element.children = [];
        } else if (element.detail.generationNo <= this.life) {
          this.ceshi(element.children);
        }
      });

      console.log("🚀 ~ getFullSpectrum ~ val:", val);
    },

    flattenTreeData(array, life) {
      let zaishi = false;
      for (let i = 0; i < array.length; i++) {
        // live=1是在世
        if (array[i].detail.live === 1) {
          zaishi = true;
          break;
        }
      }

      if (zaishi) return;
      this.life = this.life + 1;

      let arr = [];

      for (let index = 0; index < array.length; index++) {
        for (let idx = 0; idx < array[index].children.length; idx++) {
          arr.push(array[index].children[idx]);
        }
      }

      this.flattenTreeData(arr, life);
    },

    details() {
      this.dialogVisible = true;
    },
  },
  mounted() {
    // this.tree();
  },
};
</script>

<style scoped>
.active {
  border-bottom: 2px solid #bf1c29;
}

.surname {
  width: 100%;
  height: 1000px;
  background-color: #fff;

  z-index: 99;
}
.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}
.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}
.tree {
  width: 100%;
  height: calc(100% - 52px);
  overflow: auto;
}

#main2 {
  width: 100%;
  height: 500px;
  z-index: 99999999999;
}
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f8f8f8;
}
/* .top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top .logo {
  width: 170px;
  height: 73px;
}
.logo > img {
  width: 100%;
  height: 100%;
} */

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top .logo {
  /* width: 14%; */
  height: 73px;
  display: flex;
  align-items: center;
}
.logo > .logo-left > img {
  width: 100%;
  height: 100%;
}
.logo-left {
  width: 50px;
  height: 66px;
}
.logo-right {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.top .tab {
  /* width: 612px; */
  width: 24%;
  display: flex;
  justify-content: space-between;
}
.top .tab .box {
  /* font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: 500; */
  cursor: pointer;
  height: 35px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.banner {
  width: 100%;
  height: 400px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: calc(1996px - 645px - 161px);
  position: relative;
}
.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}
.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}
.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc(1996px - 645px - 161px);
  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}
.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}
.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}
.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}

/* .tree ul {
  display: flex;
  margin: 0;
  padding: 20px 0 0;
  position: relative;
  transition: all 0.5s;
}
.tree li {
  position: relative;
  padding: 20px 5px 0;
  text-align: center;
  list-style-type: none;
  transition: all 0.5s;
}
.tree li::before,
.tree li::after {
  position: absolute;
  top: 0;
  right: calc(50% - 1px);
  width: calc(50% + 1px);
  height: 20px;
  border-top: 2px solid #999;
  content: "";
}
.tree li::after {
  right: auto;
  left: calc(50% - 1px);
  border-left: 2px solid #999;
}
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}
.tree li:only-child {
  padding-top: 0;
}
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
.tree li:last-child::before {
  border-right: 2px solid #999;
}
.tree ul ul::before {
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  width: 0;
  height: 20px;
  border-left: 2px solid #999;
  content: "";
}
.qq {
  display: inline-block;
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 120px;
  height: 200px;
}
.qa {
  color: #666;
  width: 120px;
  height: 170px;
  padding: 10px 10px 2px 10px;
  border: 1px dashed #ddccb9;
  font-size: 12px;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
}
.qa img {
  width: 90px;
  height: 100px;
}
.qb {
  width: 70px;
  height: 25px;
  background-color: #fff;
  background: url(../assets/png/bwh/图层\ 94.png);
  background-size: 100% 100%;
  position: absolute;
  top: 95px;
  left: 25px;
  z-index: 9;
}
.qc {
  width: 70px;
  height: 25px;
  background-color: #fff;
  text-align: center;
  line-height: 25px;
  font-size: 17px;
  color: #999;
  position: absolute;
  top: 95px;
  left: 25px;
}
.qd {
  position: absolute;
  bottom: -25px;
  left: 35px;
  font-size: 16px;
  color: #999;
} */
</style>
