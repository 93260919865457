var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top"},[_c('div',{staticClass:"logo"},[_vm._m(0),_c('div',{staticClass:"logo-right"},[_c('p',{staticStyle:{"font-size":"30px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticStyle:{"font-size":"20px"}},[_vm._v("www.zhzqw.cn")])])]),_c('div',{staticClass:"tab"},[_c('div',{staticClass:"box",on:{"click":_vm.homePage}},[_vm._v("首页")]),_c('div',{staticClass:"box",on:{"click":_vm.noticeClick}},[_vm._v("通知")]),_c('div',{staticClass:"box",on:{"click":_vm.enterpriseClick}},[_vm._v("企业")]),_c('div',{staticClass:"box actives"},[_vm._v("族谱")]),_c('div',{staticClass:"box",on:{"click":_vm.ancestryClick}},[_vm._v("家谱树")])])]),_vm._m(1),_c('div',{staticClass:"content"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"mune"},[_c('div',{staticClass:"mune-top"},[_vm._v("目录")]),_vm._l((_vm.familyFirmList),function(item,index){return _c('div',{key:index,staticClass:"mune-item",staticStyle:{"z-index":"9999","cursor":"pointer"},on:{"click":function($event){return _vm.letterClcik(item)}}},[_vm._v(" "+_vm._s(item.label)+" "),_c('div',{class:[item.label == _vm.isActive ? 'active' : ''],staticStyle:{"z-index":"9999999"}})])})],2),_c('div',{staticClass:"surname"},[_c('div',{staticClass:"top2"},[_vm._v("族谱")]),_c('div',{staticStyle:{"width":"100%","height":"calc(100% - 52px - 131px)","padding":"50px 67px 131px 40px","box-sizing":"border-box","display":"flex","flex-direction":"column"}},_vm._l((_vm.childrenList),function(item,index){return _c('div',{key:index,staticClass:"con1"},[_c('p',[_vm._v(_vm._s(item.label))]),_c('router-link',{staticStyle:{"cursor":"pointer","z-index":"999"},attrs:{"to":{
                path: './preview',
                query: {
                  pdf: item.value,
                },
              },"target":"_blank"}},[_vm._v(" 预览 ")])],1)}),0),_c('div',{staticClass:"bottom"})])])]),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-left"},[_c('img',{attrs:{"src":require("@/assets/png/home/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/png/home/banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-con"},[_c('div',{staticClass:"left"}),_c('div',{staticClass:"right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('div',{staticClass:"e"},[_c('div',{staticClass:"left2"},[_c('img',{attrs:{"src":require("@/assets/png/home/logo.png"),"alt":""}})]),_c('div',{staticClass:"right2"},[_c('p',[_vm._v("版权所有:  安徽图腾传承文化有限公司")]),_c('div',{staticStyle:{"display":"flex"}},[_c('p',[_vm._v("客服电话:  18963782919")]),_c('p',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 企业邮箱:  zhzqwvip@163.com ")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('p',[_vm._v("备案号:  "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"_blank"}},[_vm._v("皖ICP备1833721395-1号")])]),_c('p',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 公安备案:  皖公网安备 34019202000232号 ")]),_c('a',{staticStyle:{"margin-left":"20px"},attrs:{"href":"https://client.zqyp.net/","target":"_blank"}},[_vm._v("编委会管理中心")])])])])])
}]

export { render, staticRenderFns }