<template>
  <div>
    <div class="top">
      <div class="logo">
        <img src="@/assets/png/home/zqw.png" alt="" />
      </div>
      <div class="tab">
        <div class="box acitves" @click="toHome()">首页</div>
        <div class="box" @click="toHundred()">百家姓</div>
        <div class="box" @click="toAncestors()">寻亲问祖</div>
        <div class="box" @click="toGenealogy()">家谱库</div>
        <div class="box" @click="toPartyBuilding()">党建学习</div>
        <div class="box" @click="toDownload()">下载中心</div>
        <div class="box" @click="toAbout()">关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div style="display: flex; justify-content: space-between">
          <div class="font">
            <div class="top1">
              <img src="@/assets/png/home/smallLogo.png" alt="" />
              <p>百家姓</p>
              <div class="xian"></div>
              <div class="more" @click="toHundred">+更多</div>
            </div>
            <div
              class="surname"
              style="
                display: flex;
                flex-wrap: wrap;
                /* justify-content: space-between; */
              "
            >
              <div
                class="zi"
                v-for="(item, index) in slicedArrayList"
                :key="index"
                :style="{
                  backgroundColor: item.haveFamily == true ? '#FFFFE0' : '',
                }"
                @click="surnamesClick(item)"
              >
                {{ item.lastName }}
              </div>
            </div>
          </div>
          <div class="card">
            <div class="top1">
              <img src="@/assets/png/home/smallLogo.png" alt="" />
              <p>寻亲问祖</p>
              <div class="xian1"></div>
              <div class="more" @click="toAncestors">+更多</div>
            </div>
            <div
              class="relatives"
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 639px;
              "
            >
              <div
                class="cards"
                v-for="(item, index) in menusList"
                :key="index"
                style="cursor: pointer; z-index: 99999"
                @click="relativesClick(item)"
              >
                <img src="@/assets/png/home/relatives.png" alt="" />
                <div class="co">
                  <div class="a">
                    <div style="display: flex">
                      <p>姓名:&nbsp;&nbsp;</p>
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="d">
                      <div class="item"></div>
                      <p>寻亲时间:&nbsp;&nbsp;</p>
                      <p>{{ item.createTime }}</p>
                    </div>
                  </div>
                  <div class="a b">
                    <p>字辈:&nbsp;&nbsp; {{ item.generationPoetry }}</p>
                  </div>
                  <div class="a c">
                    <p>手机:&nbsp;&nbsp;{{ item.phone }}</p>
                    <p>祖籍:&nbsp;&nbsp;{{ item.areaName }}</p>
                  </div>
                  <div class="le"></div>
                  <div class="ri"></div>
                </div>
              </div>
              <!--  <div class="cards" style="margin-top: 35px">
              <img src="@/assets/png//home/relatives.png" alt="" />
              <div class="co">
                <div class="a">
                  <div style="display: flex">
                    <p>姓名:&nbsp;&nbsp;</p>
                    <p>王志刚</p>
                  </div>
                  <div class="d">
                    <div class="item"></div>
                    <p>寻求时间:&nbsp;&nbsp;</p>
                    <p>2023/08/32</p>
                  </div>
                </div>
                <div class="a b">
                  <p>字辈: &nbsp;&nbsp;立、志、守、先、泽、逢、时、耀、盛</p>
                </div>
                <div class="a c">
                  <p>手机:&nbsp;&nbsp;17681179063</p>
                  <p>祖籍:&nbsp;&nbsp;安徽合肥</p>
                </div>
                <div class="le"></div>
                <div class="ri"></div>
              </div>
            </div>
            <div class="cards" style="margin-top: 35px">
              <img src="@/assets/png//home/relatives.png" alt="" />
              <div class="co">
                <div class="a">
                  <div style="display: flex">
                    <p>姓名:&nbsp;&nbsp;</p>
                    <p>王志刚</p>
                  </div>
                  <div class="d">
                    <div class="item"></div>
                    <p>寻求时间:&nbsp;&nbsp;</p>
                    <p>2023/08/32</p>
                  </div>
                </div>
                <div class="a b">
                  <p>字辈:&nbsp;&nbsp; 立、志、守、先、泽、逢、时、耀、盛</p>
                </div>
                <div class="a c">
                  <p>手机:&nbsp;&nbsp;17681179063</p>
                  <p>祖籍:&nbsp;&nbsp;安徽合肥</p>
                </div>
                <div class="le"></div>
                <div class="ri"></div>
              </div>
            </div>
            <div class="cards" style="margin-top: 35px">
              <img src="@/assets/png//home/relatives.png" alt="" />
              <div class="co">
                <div class="a">
                  <div style="display: flex">
                    <p>姓名:&nbsp;&nbsp;</p>
                    <p>王志刚</p>
                  </div>
                  <div class="d">
                    <div class="item"></div>
                    <p>寻求时间:&nbsp;&nbsp;</p>
                    <p>2023/08/32</p>
                  </div>
                </div>
                <div class="a b">
                  <p>字辈:&nbsp;&nbsp; 立、志、守、先、泽、逢、时、耀、盛</p>
                </div>
                <div class="a c">
                  <p>手机:&nbsp;&nbsp;17681179063</p>
                  <p>祖籍:&nbsp;&nbsp;安徽合肥</p>
                </div>
                <div class="le"></div>
                <div class="ri"></div>
              </div>
            </div> -->
            </div>
          </div>
        </div>
        <div style="width: 100%; margin-top: 100px">
          <div class="top1">
            <img src="@/assets/png/home/smallLogo.png" alt="" />
            <p>下载中心</p>
            <div class="xian2"></div>
            <div class="more" @click="toDownload">+更多</div>
          </div>
          <div class="download">
            <div class="box1">
              <div class="top2">
                <div class="left1">
                  <img src="@/assets/png/home/windows.png" alt="" />
                </div>
                <div class="right1">
                  <p style="font-size: 23px">众亲云谱客户端</p>
                  <p style="font-size: 15px">{{ clientVersion }} 版本</p>
                </div>
              </div>
              <div class="con1 images">
                <img
                  src="@/assets/png/home/download.png"
                  alt=""
                  @click="downloadExe()"
                />
              </div>
            </div>
            <div
              class="qRCode"
              :style="qRCodeShow === false ? coverImgUrl : ''"
              @mouseover="qRCodeShow = true"
              @mouseout="qRCodeShow = false"
            >
              <div class="top2" v-show="qRCodeShow === false">
                <div class="left1">
                  <img src="@/assets/png/home/Android.png" alt="" />
                </div>
                <div class="right1">
                  <p style="font-size: 23px">众亲APP</p>
                  <p style="font-size: 15px">{{ androidVersion }} 版本</p>
                </div>
              </div>
              <div class="con1" v-show="qRCodeShow === false">
                <img src="@/assets/png/home/scanCode.png" alt="" />
              </div>

              <!-- 二维码图片 -->
              <div v-show="qRCodeShow === true">
                <div
                  id="qrCode"
                  ref="qrCodeDiv"
                  style="margin-left: 20px"
                ></div>
              </div>
            </div>

            <div class="box1">
              <div class="top2">
                <div class="left1">
                  <img src="@/assets/png/home/ios.png" alt="" />
                </div>
                <div class="right1">
                  <p style="font-size: 23px">众亲APP</p>
                  <!-- <p style="font-size: 15px">苹果IOS 13以上版本</p> -->
                  <p style="font-size: 15px">
                    请到苹果应用商店搜索《众亲》获取
                  </p>
                </div>
              </div>
              <div class="con1">
                <img src="@/assets/png/home/AppStore.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- "版权所有:安徽图腾传承文化有限公司
    客服电话:18963782919     企业邮箱:zhzqwvip@163.com
    备案号:皖ICP备1833721395-1号     公安备案:皖公网安备 34019202000232号     编委会管理中心" -->
    <!-- <el-button type="primary">主要按钮</el-button> -->

    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <div style="text-align: left">
            版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司
          </div>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: "home",
  data() {
    return {
      url: "",

      clientVersion: "",

      androidVersion: "",

      qRCodeShow: false,

      coverImgUrl: {
        backgroundImage:
          "url(" + require("@/assets/png/home/roundedRectangle.png") + ")",
      },

      // 寻亲问祖
      menusList: [],
      // 百家姓
      slicedArrayList: [],
      // 定义字体对象
      iconsObj: {
        125: "iconfont icon-user",
        103: "iconfont icon-tijikongjian",
        101: "iconfont icon-shangpin",
        102: "iconfont icon-danju",
        145: "iconfont icon-baobiao",
      },
      //激活的菜单项目
      activePath: "",
      // 控制左侧菜单列表的折叠布尔值
      isCollapse: false,
    };
  },
  created() {
    // 百家姓
    this.getSlicedArray();
    // 寻亲问祖
    this.getMenus();

    // 获取安卓二维码
    this.getAndroidCode();

    // this.activePath = localStorage.getItem('path')
  },
  methods: {
    downloadExe() {
      console.log("🚀 ~ downloadExe ~ this.url:", this.url);
      window.open(this.url);
    },

    // 二维码数据
    bindQRCode: function (url) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },

    async getAndroidCode() {
      // const { data: res } = await this.instance1.post("appVersion/getList");
      const { data: res } = await this.instance2.post("appVersion/getList");
      console.log("🚀 ~ getAndroidCode ~ res:", res);
      res.data.forEach((element) => {
        if (element.type === 1 && element.url !== null) {
          // this.qRCodeShow=false
          this.bindQRCode(element.url);

          this.androidVersion = element.version;
        } else if (element.type === 3 && element.url !== null) {
          this.url = element.url;
          this.clientVersion = element.version;
        }
      });
    },

    relativesClick(item) {
      this.$router.push({
        path: "./noticelist",
        query: {
          item: item,
        },
      });
    },

    surnamesClick(item) {
      console.log(item, "item");
      this.$router.push({
        // path: "./surname",
        path: "./surnameDetails",
        query: {
          lastName: item.lastName,
        },
      });
    },

    // 百家姓

    async getSlicedArray() {
      const { data: res } = await this.instance1.get("familyNames/findList");
      // console.log(res, "res");
      this.slicedArrayList = res.data.slice(0, 80);
    },

    // 寻亲问祖

    async getMenus() {
      const { data: res } = await this.instance1.post("seekRoot/getList", {
        currentPage: 1,
        pageSize: 4,
      });
      // console.log(res, "res");
      this.menusList = res.data.list.slice(0, 4);
    },

    // 首页
    toHome() {
      // this.$router.push({
      //   path: "/",
      // });
    },
    // 百家姓
    toHundred() {
      this.$router.push({
        path: "./surnameList",
      });
    },
    toAncestors() {
      this.$router.push({
        path: "./ancestors",
      });
    },
    // 家谱库
    toGenealogy() {
      this.$router.push({
        path: "./genealogy",
      });
      // location.href = "./genealogy.html";
    },

    // 党建学习
    toPartyBuilding() {
      this.$router.push({
        path: "./partyBuilding",
      });
    },

    // 下载中心
    toDownload() {
      this.$router.push({
        path: "./download",
      });
      // location.href = "./download.html";
    },
    toAbout() {
      this.$router.push({
        path: "./about",
      });
      // location.href = "./about.html";
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  width: 170px;
  height: 73px;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.top .tab {
  width: 38%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.acitves {
  border-bottom: 2px solid #cd4f4b;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc(1996px - 645px - 161px);
  position: relative;
}

.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}

.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}

.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc(1996px - 645px - 161px);

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.con .font {
  width: 60%;
  height: 711px;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}

.xian {
  width: 85%;
  height: 1px;
  background-color: #dddddd;
}

.xian1 {
  width: 65%;
  height: 1px;
  background-color: #dddddd;
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  cursor: pointer;
  color: #c2c2c2;
}

.card {
  width: 35%;
  height: 711px;
}

.zi {
  cursor: pointer;
  width: 70px;
  height: 70px;
  margin-right: 6.5px;
  background: url(@/assets/png/home/grid.png) no-repeat;
  background-size: 100% 100%;
  font-size: 24px;
  text-align: center;
  line-height: 70px;
  margin-top: 10px;
  z-index: 999;
}

.cards {
  width: 100%;
  position: relative;
  height: 150px;
  font-size: 18px;
  font-weight: normal;

  color: #616161;
}

.cards img {
  width: 100%;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
}

.co {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(@/assets/png/home/3.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.le {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/png/home/2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.ri {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url(@/assets/png/home/4.png);
  background-repeat: no-repeat;
  background-size: 110% 130%;
  background-position: -37px -40px;
}

.a {
  width: 100%;
  height: 20%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 14px;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
}

.b {
  margin-top: 10px;
  padding-right: 40px;
}

.c {
  margin-top: 10px;
  padding-right: 40px;
}

.d {
  width: 211px;
  height: 21px;
  background-image: url(@/assets/png/home/5.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 22;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}

.item {
  width: 14px;
  height: 14px;
  background-image: url(@/assets/png/home/time.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

body {
  background-color: #f8f8f8;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.download {
  width: 100%;
  height: 246px;
  display: flex;
  justify-content: space-around;
}

.box1 {
  width: 31%;
  height: 226px;
  background-image: url(@/assets/png/home/roundedRectangle.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.qRCode {
  width: 31%;
  height: 226px;
  background-color: #fff;
  border-radius: 28px;
  /* background-image: url(@/assets/png/home/roundedRectangle.png); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  z-index: 99999 !important;
}

.images {
  cursor: pointer;
  z-index: 99999 !important;
}

.top2 {
  width: 80%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.left1 {
  width: 60px;
  height: 60px;
}

.right1 {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}

.con1 {
  width: 55px;
  height: 55px;
}

.con1 img {
  width: 100%;
  height: 100%;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
