var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top"},[_vm._m(0),_c('div',{staticClass:"tab"},[_c('div',{staticClass:"box acitves",on:{"click":function($event){return _vm.toHome()}}},[_vm._v("首页")]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.toHundred()}}},[_vm._v("百家姓")]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.toAncestors()}}},[_vm._v("寻亲问祖")]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.toGenealogy()}}},[_vm._v("家谱库")]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.toDownload()}}},[_vm._v("下载中心")]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.toAbout()}}},[_vm._v("关于我们")])])]),_vm._m(1),_c('div',{staticClass:"content"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"surname"},[_c('div',{staticClass:"top2"},[_vm._v(_vm._s(_vm.$route.query.lastName)+"氏编委会")]),_c('div',{staticStyle:{"width":"100%","height":"calc(100% - 52px - 131px)","padding":"50px 67px 131px 40px","box-sizing":"border-box","display":"flex","flex-direction":"column"}},_vm._l((_vm.slicedArrayList),function(item,index){return _c('div',{key:index,staticClass:"con1"},[_c('router-link',{staticStyle:{"cursor":"pointer","z-index":"999"},attrs:{"to":{
                path: './editorialBoard',
                query: {
                  familyTreeId: item.familyTreeId,
                  name: item.name,
                },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),0),_c('div',{staticClass:"bottom"})])])]),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/png/home/zqw.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/png/home/banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-con"},[_c('div',{staticClass:"left"}),_c('div',{staticClass:"right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('div',{staticClass:"e"},[_c('div',{staticClass:"left2"},[_c('img',{attrs:{"src":require("@/assets/png/home/logo.png"),"alt":""}})]),_c('div',{staticClass:"right2"},[_c('p',[_vm._v("版权所有:  安徽图腾传承文化有限公司")]),_c('div',{staticStyle:{"display":"flex"}},[_c('p',[_vm._v("客服电话:  18963782919")]),_c('p',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 企业邮箱:  zhzqwvip@163.com ")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('p',[_vm._v("备案号:  "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"_blank"}},[_vm._v("皖ICP备1833721395-1号")])]),_c('p',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 公安备案:  皖公网安备 34019202000232号 ")]),_c('a',{staticStyle:{"margin-left":"20px"},attrs:{"href":"https://client.zqyp.net/","target":"_blank"}},[_vm._v("编委会管理中心")])])])])])
}]

export { render, staticRenderFns }