<template>
  <div>
    <div class="top">
      <div class="logo">
        <img src="@/assets/png/home/zqw.png" alt="" />
      </div>
      <div class="tab">
        <div class="box" @click="toHome()">首页</div>
        <div class="box" @click="toHundred()">百家姓</div>
        <div class="box acitves" @click="toAncestors()">寻亲问祖</div>
        <div class="box" @click="toGenealogy()">家谱库</div>
        <div class="box" @click="toPartyBuilding()">党建学习</div>
        <div class="box" @click="toDownload()">下载中心</div>
        <div class="box" @click="toAbout()">关于我们</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <!-- 姓氏:朱氏
字辈:圣。守，先
联系人:朱晓峰
联系电话:15156991066
祖籍:安徽省合肥市蜀山区
其他信息:
 -->
        <div class="surname">
          <div class="top2">寻亲问祖</div>
          <div
            style="
              width: 100%;
              height: calc(100% - 52px - 131px);
              padding: 50px 67px 131px 40px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
            "
          >
            <div class="con1">
              <p>姓氏:&nbsp;&nbsp;{{ itemList.lastName }}</p>
            </div>
            <div class="con1">
              <p>字辈:&nbsp;&nbsp;{{ itemList.generationPoetry }}</p>
            </div>
            <div class="con1">
              <p>联系人:&nbsp;&nbsp;{{ itemList.name }}</p>
            </div>
            <div class="con1">
              <p>联系电话:&nbsp;&nbsp;{{ itemList.phone }}</p>
            </div>
            <div class="con1">
              <p>祖籍:&nbsp;&nbsp;{{ itemList.areaName }}</p>
            </div>
            <div class="con1">
              <p>其他信息:&nbsp;&nbsp;{{ itemList.remarks }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slicedArrayList: [],
      currentPage: 1,
      pageSize: 8,
      // 总数
      total: 0,
      itemList: {},
    };
  },

  watch: {
    "$route.query": {
      immediate: true,
      handler(val, oldval) {
        // console.log(val.item, oldval, "val, oldval");
        // this.getFamilyFirm();
        if (val.item != undefined) {
          console.log(val.item, oldval, "val,oldval");
          this.itemList = val.item;
          // 家族企业
          //  this.search();
        }
      },
    },
  },

  methods: {
    // // 家族企业
    // async getFamilyFirm() {
    //   const { data: res } = await this.instance1.get(
    //     `seekRoot/contactPlatform/${this.$route.query.id}`
    //   );

    //   console.log(res.data, "res.data");

    //   // this.familyFirmList = res.data.list;
    //   // this.total = res.data.total;
    // },
    // 首页
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    // 百家姓
    toHundred() {
      this.$router.push({
        path: "./surnameList",
      });
    },
    // 寻亲问祖
    toAncestors() {
      this.$router.push({
        path: "./ancestors",
      });
    },
    // 家谱库
    toGenealogy() {
      this.$router.push({
        path: "./genealogy",
      });
      // location.href = "./genealogy.html";
    },

    // 党建学习
    toPartyBuilding() {
      this.$router.push({
        path: "./partyBuilding",
      });
    },

    // 下载中心
    toDownload() {
      this.$router.push({
        path: "./download",
      });
      // location.href = "./download.html";
    },
    // 关于我们
    toAbout() {
      this.$router.push({
        path: "./about",
      });
    },
  },
};
</script>

<style scoped>
.surname {
  width: 100%;
  height: 500px;
  background-color: #fff;
}

.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.con1 {
  display: flex;
  margin-bottom: 30px;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f8f8f8;
}

.active {
  border-bottom: 2px solid #bf1c29;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .logo {
  width: 170px;
  height: 73px;
}

.logo > img {
  width: 100%;
  height: 100%;
}

.top .tab {
  width: 38%;
  display: flex;
  justify-content: space-between;
}

.top .tab .box {
  /* font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: 500; */

  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.acitves {
  border-bottom: 2px solid #cd4f4b;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc((1996px - 645px - 161px) / 2);
  position: relative;
  overflow: hidden;
}

.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc((1996px - 645px - 161px) / 2);
  display: flex;
}

.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}

.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc((1996px - 645px - 161px) / 2);

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}

.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}

.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}

.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
