<template>
  <div>
    <div class="top">
      <div class="logo">
        <div class="logo-left">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="logo-right">
          <p style="font-size: 30px; font-weight: bold">{{ title }}</p>
          <p style="font-size: 20px">www.zhzqw.cn</p>
        </div>
      </div>
      <div class="tab">
        <div class="box" @click="$router.push('./editorialBoard')">首页</div>
        <div class="box" @click="$router.push('./ancestorsdetalis')">通知</div>
        <!-- <div class="box">组织</div> -->
        <div class="box" @click="$router.push('./enterprise')">企业</div>
        <div class="box" @click="$router.push('./genealogys')">族谱</div>
        <div class="box" @click="$router.push('./tree')">家谱树</div>
        <!-- <div class="box">宗亲分布</div> -->
        <!-- <div class="box" @click="$router.push('./celebrity')">名人</div>
        <div class="box active">文化典故</div>
        <div class="box" @click="$router.push('./merit')">功德</div> -->
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/png/home/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content-con">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="con">
        <div class="surname">
          <div class="top2">文化典故</div>
          <div
            style="
              width: 100%;
              height: calc(100% - 52px - 131px);
              padding: 50px 67px 131px 40px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
            "
          >
            <div
              class="con1"
              v-for="(item, index) in slicedArrayList"
              :key="index"
            >
              <p
                style="cursor: pointer; z-index: 99999"
                @click="familyDetails(item)"
              >
                {{ item.title }}
              </p>
              <p>阅读：{{ item.hits }}</p>
            </div>
            <!-- <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div>
              <div class="con1">
                <p>安徽合肥蜀山张氏编委会</p>
                <p>阅读：100</p>
              </div> -->
          </div>
          <div class="bottom">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              style="z-index: 999"
              class="custom-pagination"
              @current-change="handleSizeChange"
            >
            </el-pagination>

            <!-- <div class="box2">首页</div>
              <div class="box2">上一页</div>
              <div class="box2">1</div>
              <div class="box2">下一页</div>
              <div class="box2">尾页</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="e">
        <div class="left2">
          <img src="@/assets/png/home/logo.png" alt="" />
        </div>
        <div class="right2">
          <p>版权所有:&nbsp;&nbsp;安徽图腾传承文化有限公司</p>
          <div style="display: flex">
            <p>客服电话:&nbsp;&nbsp;18963782919</p>
            <p style="margin-left: 20px">
              企业邮箱:&nbsp;&nbsp;zhzqwvip@163.com
            </p>
          </div>
          <div style="display: flex">
            <p>备案号:&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备1833721395-1号</a></p>
            <p style="margin-left: 20px">
              公安备案:&nbsp;&nbsp;皖公网安备 34019202000232号
            </p>
            <!-- <p style="margin-left: 20px">编委会管理中心</p> -->
            <a
              href="https://client.zqyp.net/"
              target="_blank"
              style="margin-left: 20px"
              >编委会管理中心</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: localStorage.getItem("val"),
      slicedArrayList: [],

      currentPage: 1,
      pageSize: 12,
      // 总数
      total: 0,
    };
  },

  created() {
    // 百家姓
    this.getSlicedArray();
  },
  methods: {
    familyDetails(item) {
      this.$router.push({
        path: "./notice",
        query: {
          id: item.id,
          selectedItem: "4",
        },
      });
      console.log(item, "item");
    },

    // 切换分页
    handleSizeChange(val) {
      this.currentPage = val;
      // 百家姓
      this.getSlicedArray();

      console.log(val, "val");
    },

    async getSlicedArray() {
      const { data: res } = await this.instance1.post("article/getList", {
        type: 5,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        familyTreeId: localStorage.getItem("familyTreeId"),
      });

      this.slicedArrayList = res.data.list;
      this.total = res.data.total;
      console.log(res, "res");
    },
  },
};
</script>

<style scoped>
::v-deep .custom-pagination .el-pager .active {
  /* 设置自定义的页码背景颜色 */
  background-color: #bf1b28 !important;
}
::v-deep .custom-pagination .el-pager li:hover {
  color: #bf1b28 !important;
}

::v-deep .custom-pagination .el-pager .active:hover {
  /* 设置自定义的页码背景颜色 */
  color: #fff !important;
}

.surname {
  width: 100%;
  height: 1000px;
  background-color: #fff;
}
.top2 {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #bf1c29;
  font-size: 20px;
  padding-left: 27px;
  color: #333333;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.con1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.bottom {
  width: 100%;
  height: 131px;

  display: flex;
  align-items: center;
  padding-left: 40px;
}
.box2 {
  padding: 10px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}

* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f8f8f8;
}

.active {
  border-bottom: 2px solid #bf1c29;
}

.top {
  width: 100%;
  height: 145px;
  background: url(@/assets/png/home/top.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top .logo {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
}
.logo > .logo-left > img {
  width: 100%;
  height: 100%;
}
.logo-left {
  width: 50px;
  height: 66px;
}
.logo-right {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.top .tab {
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.top .tab .box {
  /* font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #000000;
    font-weight: 500; */

  cursor: pointer;
  height: 35px;
  font-family: MicrosoftYaHei;
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}
.banner {
  width: 100%;
  height: 400px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: calc(1996px - 645px - 161px);
  position: relative;
}
.content .content-con {
  position: absolute;
  left: 16%;
  width: 67%;
  height: calc(1996px - 645px - 161px);
  display: flex;
}
.content .content-con .left {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/left.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  margin-bottom: 230px;
  z-index: 22;
}
.content .content-con .right {
  width: 50%;
  height: calc(1996px - 645px - 161px - 280px);
  background: url(@/assets/png/home/right.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 280px;
  z-index: 22;
}

.con {
  width: 67%;
  height: calc(1996px - 645px - 161px);

  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
  position: relative;
}
.xian2 {
  width: 88%;
  height: 1px;
  background-color: #dddddd;
}
.more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #c2c2c2;
}

.e {
  width: 62%;
  height: 100%;
  display: flex;
  align-items: center;
}
.left2 {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right2 {
  width: 85%;
  height: 60%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.foot {
  width: 100%;
  height: 161px;
  background-image: url(@/assets/png/home/bottom.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 20%;
  box-sizing: border-box;
}
</style>
